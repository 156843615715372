import React, { FC } from 'react'
import styled, { StyledProps } from 'styled-components'
import { Box } from '@ucheba/ui/components/Box/touch'
import { List, ListItem } from '@ucheba/ui/components/List/touch'
import { Text } from '@ucheba/ui/components/Text/touch'
import { Formik, Field, ErrorMessage } from 'formik'
import { TextField } from '@ucheba/ui/components/TextField/touch'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/touch'
import { Button } from '@ucheba/ui/components/Button/touch'
import { ErrorText } from '@ucheba/ui/components/ErrorText/touch'
import { EDataKeys } from '@ucheba/utils/types/dataKeys'
import { Form } from '@ucheba/ui/components/Form/desktop'
import { authPhoneCodeSelectors } from '@ucheba/store/auth/phone/code'
import { useSelector } from 'react-redux'
import { EButtonColorByTheme, EInputsColor, ILidMagnetFormProps } from '../types'
import ImgLarge from '../assets/img__main_touch.png'
import { useLidMagnetForm } from '../bll/useLidMagnetForm'
import PhoneConfirmDialog from './PhoneConfirmDialog'
import MessengersDialog from './MessengersDialog'

const Block = styled(Box)`
  border-radius: 8px;
  padding: 0;
`

const BoxSC = styled(Box)``

const Img = styled.img`
  width: 16px;
`

const ImgMain = styled.img`
  width: 100%;
`

const TextST = styled(Text)`
  letter-spacing: 1.4px;
`

const GridColSC = styled<StyledProps<any>>(GridCol)`
  display: ${(props) => (props.showInputs ? 'block' : 'none')};
`

const LidMagnetForm: FC<ILidMagnetFormProps> = (props) => {
  const {
    title,
    list,
    text,
    funnel,
    productType,
    requestTarget,
    onSubmitForm,
    onCheckedCode,
    withMessengers = true,
    color = 'plum',
    buttonText,
    imgUrl,
    overTitle,
    children,
    titleSize = 'h2',
    showInputs = true,
  } = props

  const errorPhone = useSelector(authPhoneCodeSelectors.error)

  const {
    initialValues,
    onSubmit,
    getImg,
    onPhoneConfirmSubmit,
    onPhoneConfirmClose,
    needConfirmPhone,
    needShowMessengers,
    onMessengersDialogClose,
    phone,
    isLoading,
  } = useLidMagnetForm(funnel, productType, requestTarget, onSubmitForm, onCheckedCode)

  return (
    <Block color={color as any}>
      <BoxSC color={color as any} spacing='small'>
        {children}

        {text || title || list ? (
          <>
            {overTitle && (
              <>
                <Spacing spacing='usmall' />

                <TextST uppercase>{overTitle}</TextST>

                <Spacing spacing='usmall' />
              </>
            )}

            {title && (
              <Text fontSize={titleSize} lineHeight={titleSize} fontWeight='extrabold'>
                {title}
              </Text>
            )}

            {text && (
              <>
                <Spacing spacing='xxsmall' />

                <Text>{text}</Text>
              </>
            )}

            <Spacing spacing='small' />

            {list && (
              <List spacing='xxsmall'>
                {list.map((txt, key) => {
                  return (
                    <ListItem
                      alignItems='flex-start'
                      key={key}
                      StartIcon={getImg(key) ? <Img src={getImg(key)} /> : undefined}
                    >
                      <Text fontSize='h4' lineHeight='h4' fontWeight='bold' color='white'>
                        {txt}
                      </Text>
                    </ListItem>
                  )
                })}
              </List>
            )}

            <Spacing spacing='small' />
          </>
        ) : null}

        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {(): JSX.Element => (
            <Form fillByProfile>
              <Grid spacing='small'>
                <GridColSC showInputs={showInputs}>
                  <Field
                    as={TextField}
                    name={EDataKeys.name}
                    label='Имя'
                    color={EInputsColor[color]}
                    required
                  />

                  <ErrorMessage component={ErrorText} name={EDataKeys.name} />
                </GridColSC>

                <GridColSC showInputs={showInputs}>
                  <Field
                    as={TextField}
                    name={EDataKeys.phone}
                    label='Телефон'
                    color={EInputsColor[color]}
                    required
                    type='tel'
                  />

                  <ErrorMessage component={ErrorText} name={EDataKeys.phone} />

                  {errorPhone && (
                    <ErrorText color={color === 'gold' ? 'red' : 'white'}>
                      {errorPhone?.message}
                    </ErrorText>
                  )}
                </GridColSC>

                <GridCol>
                  <Button
                    color={EButtonColorByTheme[color] || 'gold'}
                    block
                    type='submit'
                    progress={isLoading}
                  >
                    {buttonText || 'Отправить'}
                  </Button>
                </GridCol>
              </Grid>
            </Form>
          )}
        </Formik>
      </BoxSC>

      <ImgMain src={imgUrl || ImgLarge} />
      {phone && needConfirmPhone ? (
        <PhoneConfirmDialog
          open={needConfirmPhone}
          phone={phone}
          onSubmit={onPhoneConfirmSubmit}
          onClose={onPhoneConfirmClose}
          isLoading={isLoading}
        />
      ) : null}
      <MessengersDialog
        open={needShowMessengers && withMessengers}
        onClose={onMessengersDialogClose}
      />
    </Block>
  )
}

export default LidMagnetForm
